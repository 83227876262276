import cryptoLib from 'cryptlib';
import globals from './constant';
import CryptoJS from 'crypto-js';
import axios from 'axios';
// import {toast } from 'react-toastify';
const shaKey = cryptoLib.getHashSha256(globals.key, 32);


let common = {

    apiCalling: async (api, method, values) => {
        // common.print("common api call ");
        return new Promise(async function (resolve, reject) {

            axios.defaults.headers.post['Content-Type'] = 'text/plain';
            axios.defaults.headers.post['api-key'] = globals.encrypted_api_key;
            axios.defaults.headers.post['role'] = 'Admin';
            axios.defaults.headers.post['email'] = localStorage.getItem('email');
            axios.defaults.headers.post['password'] = localStorage.getItem('password');

            try {
                let url = '';
                if(globals.is_production){
                    url = globals.live_api_base_url;
                }else{
                    url = globals.api_base_url;
                }
                values = await common.encryption(values);
                axios({ method: method, url:url+api, data: values })
                    .then(async function (response) {
                        const res = await common.decryption(response.data);
                        if (res.code === 1) {
                            resolve(res);
                        } else if (res.code === 0) {
                            //  toast.error(res.message)
                            resolve(res);
                        } else {
                            resolve(res);
                        }
                    }).catch(function (error) {
                        common.print(error);
                        reject(error);
                    });
            } catch (error) {

                reject(error);
            }
        });
    },
    apiCallNoEnc: async (api, method, values) => {
        // common.print("common api call ");
        return new Promise(async function (resolve, reject) {

            axios.defaults.headers.post['Content-Type'] = 'text/plain';
            axios.defaults.headers.post['api-key'] = globals.encrypted_api_key;
            axios.defaults.headers.post['role'] = 'Admin';
            axios.defaults.headers.post['email'] = localStorage.getItem('email');
            axios.defaults.headers.post['password'] = localStorage.getItem('password');

            try {           
                let url = '';
                if(globals.is_production){
                    url = globals.live_api_base_url;
                }else{
                    url = globals.api_base_url;
                }
                axios({ method: method, url:url+api, data: values })
                    .then(async function (response) {                     
                        if (response.code === 1) {
                            // alert("common-1")
                            resolve(response.data);
                        } else if (response.code === 0) {
                            // alert("common-2")
                            //  toast.error(res.message)
                            resolve(response.data);
                        } else {
                            // alert(typeof response)
                            resolve(response.data);
                        }
                    }).catch(function (error) {
                        common.print(error);
                        reject(error);
                    });
            } catch (error) {

                reject(error);
            }
        });
    },
    old_apiCalling: (values, url, method) => {
        return new Promise(async function (resolve, reject) {
            try {
                axios.interceptors.request.use(
                    async (config) => {
                        console.log("Configration interceptors :");
                        console.log(config);
                        config.headers.post['Content-Type'] = 'text/plain';
                        config.headers.post['api-key'] = globals.encrypted_api_key;
                        config.headers.post['role'] = 'Admin';
                        // config.data = await common.encryption(config.data);
                        // config.data = await common.enc(config.data);
                        return config;
                    },
                    error => Promise.reject(error)
                );

                axios.interceptors.response.use(async response => {
                    response.data = await common.decryption(response.data);
                    // response.data = await common.dec(response.data);
                    return response;
                }, function (error) {
                    return Promise.reject(error);
                });

                await axios({ method: method, url: globals.live_api_base_url, data: values })
                    .then(function (response) {
                        console.log(response);
                        resolve(response);
                    })
                    .catch(function (error) {
                        // console.log(error);
                        reject(error);
                    });
            } catch (error) {
                console.log(error);
                reject(error);
            }
        });
    },

    /*
     * Request encryption
     */
    encryption: async function (req) {
        return new Promise(function (resolve, reject) {
            // console.log("Requested encryption values : ");
            // console.log(req);
            // console.warn(req);
            try {
                const response = cryptoLib.encrypt(JSON.stringify(req), shaKey, globals.iv);
                resolve(response);
            } catch (error) {
                console.log(error)
                reject('');
            }

        });
    },

    decryption: async function (req) {
        return new Promise(function (resolve, reject) {
            if (req !== undefined || Object.keys(req).length !== 0) {
                const request = JSON.parse(cryptoLib.decrypt(req, shaKey, globals.iv));
                // console.warn(request);
                resolve(request);
            }
            else {
                // common.print("Bad decryption request", Object.keys(req))
                reject('');
            }
        });
    },

    enc: async (plainText) => {
        console.log(plainText);
        return new Promise(function (resolve, reject) {
            try {
                let decoded_iv = CryptoJS.enc.Base64.parse(globals.iv_real);
                let hash = CryptoJS.SHA256(globals.key);
                let hashHex32 = hash.toString(CryptoJS.enc.Hex).substring(0, 32);
                let aes_key = CryptoJS.enc.Utf8.parse(hashHex32);
                plainText = CryptoJS.AES.encrypt(plainText.toString(), aes_key, { iv: decoded_iv, });
                console.warn(plainText);
                resolve(plainText);
                // if (plainText !== undefined && Object.keys(plainText).length !== 0) {

                // } else {
                //     reject('');  
                // }
            } catch (error) {
                console.log(error);
                reject('');
            }

        });
    },

    dec: async (encText) => {
        return new Promise(function (resolve, reject) {
            let decoded_iv = CryptoJS.enc.Base64.parse(globals.iv_real);
            let hash = CryptoJS.SHA256(globals.key);
            let hashHex32 = hash.toString(CryptoJS.enc.Hex).substring(0, 32);
            let aes_key = CryptoJS.enc.Utf8.parse(hashHex32);
            try {
                let decrypted = CryptoJS.AES.decrypt(encText, aes_key, { iv: decoded_iv, });
                console.warn(decrypted);
                resolve(decrypted.toString(CryptoJS.enc.Utf8));
            } catch (error) {
                reject('');
            }
        });
    },
    print: (req) => {
        console.log(req);
    },
}


//export default {FirstComponent, SecondComponent}
export default common;