import React from 'react'
import { Link } from "react-router-dom";
const AdminSignup = () => {
  return (
    <div>
        <Link className="navbar-brand" to="/">Login </Link>
    <br />
        AdminSignup</div>
  )
}

export default AdminSignup