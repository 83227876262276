import React, { useState } from 'react'
import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { Box } from '@mui/material'
import {
    Avatar,
    Button,
    CssBaseline,
    TextField,
    FormControlLabel,
    Checkbox,
    Grid,
    Box,
    Typography,
    Container,
    createTheme,
    ThemeProvider
} from '@mui/material';

import LinkUrl from '@mui/material/Link';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useFormik } from 'formik';
import { Link, useNavigate  } from "react-router-dom";
import axios from 'axios';
import globals from '../../config/constant';
import common from '../../config/common';
const theme = createTheme();


const validate = values => {
    const errors = {};
    if (!values.email) {
        errors.email = 'Please enter email.';
        return errors;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address';
        return errors;
    }
    if (!values.password) {
        errors.password = 'Please enter Password.';
        return errors;
    } else if (values.password.length > 20) {
        errors.password = 'Must be 20 characters or less';
        return errors;
    } else if (values.password.length < 6) {
        errors.password = 'Password length should be 6 characters minimum';
        return errors;
    }

};


const AdminLogin = () => {
    const [credentials, setCredentials] = useState({});
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
            // remember_me: '',
        },
        validate,
        onSubmit: async (values) => {
            axios.defaults.headers.post['Content-Type'] = 'text/plain';
            axios.defaults.headers.post['api-key'] = globals.encrypted_api_key;
            axios.defaults.headers.post['role'] = 'Admin';
            try {
                values = await common.encryption(values);
                // const response = await common.apiCalling(values, "Admin/admin-login", "post");
                let url = '';
                if(globals.is_production){
                    url = globals.live_api_base_url;
                }else{
                    url = globals.api_base_url;
                }
                await axios({ method: "post", url: url + "Admin/admin-login", data: values })
                .then(async function (response) {
                   const res = await common.decryption(response.data);
                   if(res.code===1){
                    localStorage.setItem('email', res.data.email);
                    localStorage.setItem('password', res.data.password);
                    toast.success(res.message)                 
                    navigate('/contact-us-listing');
                    window.location.reload(false);                   
                   }else{
                    toast.error(res.message)
                   }                   
                   
                console.log(res);
                    console.log(response);
                    return(response);
                })
                .catch(function (error) {
                    // console.log(error);
                    return(error);                        
                });               
            } catch (error) {              
                return error;
            }
       
        },
    });



    const onChange = (e) => {
        // alert(e.target.value)
        setCredentials({ ...credentials, [e.target.name]: e.target.value })
    }
    return (
        <>
            {/* <ToastContainer /> */}
            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                sx={{ width: '100%' }}
                                id="email"
                                label="Email Address"                                
                                autoFocus
                                onChange={onChange}
                                {...formik.getFieldProps('email')}
                                // name="email"
                                // autoComplete="email"
                            />

                            {formik.touched.email && formik.errors.email ? (
                                <Box sx={{ color: 'error.main', display: 'inline' }}>{formik.errors.email}</Box>
                            ) : null}
                            <TextField
                                margin="normal"
                                required
                                sx={{ width: '100%' }}
                                // name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={onChange}

                                {...formik.getFieldProps('password')}
                            />

                            {formik.touched.password && formik.errors.password ? (
                                <Box sx={{ color: 'error.main', width: '100%' }}>{formik.errors.password}</Box>
                            ) : null}
                            <FormControlLabel
                                control={<Checkbox value="remember" color="primary" />}
                                label="Remember me"
                            />
                            <Button
                                type="submit"

                                variant="contained"
                                sx={{ mt: 3, mb: 2, width: '100%' }}
                            >
                                Sign In
                            </Button>
                            <Grid container >
                                <Grid item xs>
                                    <LinkUrl to='/admin-forgot-password' component={Link} variant="body2">
                                        Forgot password?
                                    </LinkUrl>
                                </Grid>
                                <Grid item>
                                    {/* <Link to="/"> */}
                                    <LinkUrl to='/admin-signup' component={Link} variant="body2">
                                        {"Don't have an account? Sign Up"}
                                    </LinkUrl>

                                    {/* </Link> */}
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Copyright sx={{ mt: 8, mb: 4 }} />
                </Container>
            </ThemeProvider>

        </>
    )
}

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <LinkUrl color="inherit" href="https://mui.com/">
                AdminApi.com
            </LinkUrl>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default AdminLogin