import React, { useEffect, useState, useCallback } from 'react'
// import { useNavigate, useLocation } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import common from '../../config/common';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import EditIcon from '@mui/icons-material/Edit';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import muiSelect from '@mui/material/Select';
// import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
// import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// import { useFormik } from 'formik';
// import * as Yup from 'yup';
import { toast } from 'react-toastify';
// import BlogEditor from './BlogEditor';
const EditBlog = () => {
    const theme = createTheme();
    const location = useLocation();
    // const navigate = useNavigate();
    // const [credentials, setCredentials] = useState({});
    const [blog, setBlog] = useState({
        blog_id: '',
        // blog_image: '',
        title: '',
        category: '',
        description: '',
        written_by: ''
    });
    // const [credentials, setCredentials] = useState({});

    // const [blogImageErr, setSetBlogImageErr] = useState('');
    const [titleErr, setTitleErr] = useState('');
    const [categoryErr, setCategoryErr] = useState('');
    const [descriptionErr, setDescriptionErr] = useState('');
    const [writtenByErr, setWrittenByErr] = useState('');

    const validation = useCallback(
        async () => {
            // console.log("Final credentials: ", credentials);
            // console.log("Final country: ", selectedCountry);
            return new Promise((resolve, reject) => {
                if (!blog.title) {
                    setTitleErr('Title is required.');
                    reject();
                } else if (!blog.category) {
                    setCategoryErr("Category is required.");
                    reject();
                } else if (!blog.description) {
                    setDescriptionErr("Description is required.");
                    reject();
                } else if (!blog.written_by) {
                    setWrittenByErr('Written by is required.');
                    reject();
                } else {
                    resolve();
                }
            })
        }, [blog])
    const uploadImage = async (callback) => {
        let params = blog;
        if (blog.blog_image) {
            try {
                const formData = new FormData();
                formData.append('blog_image', blog.blog_image);
                common.print("formData before uploading the blog image.");
                common.print(params);
                const imgRes = await common.apiCallNoEnc("api/upload-a-image", "post", formData)
                // const imgRes = JSON.parse(imgRes1);
                if (imgRes.code === 1) {
                    params.blog_image = imgRes.data.newFileName;
                    callback(params);
                } else {
                    callback(null);
                }
            } catch (error) {

            }
        } else {
            callback(null)
        }
    }
    // const handleDescriptionChange = (e) => {
    //     alert(e)
    //     // setChildInput(event.target.value);
    //     // setBlog({ ...blog, [e.target.name]: e.target.value });
    //     // common.print("handleDescriptionChange called: ");
    //     // common.print("handleDescriptionChange value: ", e.target.value);
    // };
    const onSubmit = async (e) => {

        e.preventDefault();
        common.print(e);
        try {

            await validation()
            uploadImage((data) => {
                // update-blog
                common.apiCalling("Admin/blog/update-blog", "post", (data) ? data : blog).then(async (res) => {
                    common.print(res)
                    // toast.error(res.message)
                    if (res.code !== 1) {
                        // error.email = res.message
                        // return error;
                        // setEmailErr("Email already registered")
                        toast.error(res.message);
                    } else {
                        toast.success(res.message);

                    }
                }).catch((err) => {
                    common.print("err through");
                    console.log(err);
                    // setEmailErr("Email already registered.");

                })
            })
        } catch (error) {
            common.print(error);
        }
    }

    useEffect(() => {
        let api_data = { blog_id: location.state.id };
        common.apiCalling("Admin/blog/blog-details", "post", api_data).then(async (res) => {
            try {
                if (res.code === 1) {
                    res.data.blog_image = '';
                    // setblog(res.data);
                    setBlog(res.data);
                    setTitleErr('');
                    setCategoryErr('');
                    setDescriptionErr('');
                    setWrittenByErr('');
                    // alert(blog.title)
                    common.print(res);
                    // try {                        
                    //     await validation()
                    // } catch (error) {

                    // }

                } else {

                }
            } catch (error) {
                console.log(error);
                common.print(error)
            }
        });


    }, [location.state.id])

    const onChange = (e) => {
        // alert(e.target.value)
        // common.print(e.target.name + " : " + e.target.value)
        // setCredentials({ ...credentials, [e.target.name]: e.target.value });


        if (e.target.name !== 'blog_image') {
            setBlog({ ...blog, [e.target.name]: e.target.value });
        } else {
            setBlog({ ...blog, [e.target.name]: e.target.files[0] });
        }

        if (e.target.name === 'title' && e.target.value !== '') {
            setTitleErr('');
        }
        if (e.target.name === 'category' && e.target.value !== '') {
            setCategoryErr('');
        }
        if (e.target.name === 'description' && e.target.value !== '') {
            setDescriptionErr('');
        }
        if (e.target.name === 'written_by' && e.target.value !== '') {
            setWrittenByErr('');
        }
    }


    return (
        <Box sx={{ width: '100%', mt: 10 }}>

            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="lg">
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <EditIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Update blog
                        </Typography>
                        <form onSubmit={onSubmit} encType="multipart/form-data">
                            <Box noValidate sx={{ mt: 3 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            autoComplete="Title"
                                            name="title"
                                            required
                                            fullWidth
                                            id="title"
                                            label="Title"
                                            onChange={onChange}
                                            value={blog.title}
                                            focused={blog.title ? true : false}
                                        // defaultValue={blog.full_name}
                                        />
                                        {titleErr &&
                                            <Box sx={{ color: 'error.main', display: 'inline' }}>{titleErr}</Box>
                                        }
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="category"
                                            label="Category"
                                            name="category"
                                            onChange={onChange}
                                            value={blog.category}
                                            focused={blog.category ? true : false}
                                        />
                                        {categoryErr &&
                                            <Box sx={{ color: 'error.main', display: 'inline' }}>{categoryErr}</Box>
                                        }
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="description"
                                            label="Description"
                                            name="description"
                                            onChange={onChange}
                                            value={blog.description} // validate={validatedescription}                                            
                                            multiline
                                            rows={10} // adjust this value to make the TextField bigger
                                            focused={blog.description ? true : false}
                                        />
                                        {descriptionErr &&
                                            <Box sx={{ color: 'error.main', display: 'inline' }}>{descriptionErr}</Box>
                                        }
                                    </Grid>
                                    {/* <Grid item xs={12}>
                                        <BlogEditor onDescriptionChange={(value) =>
                                            setBlog((prevFormData) => ({
                                                ...prevFormData,
                                                'description': value,
                                            }))
                                        } />
                                    </Grid> */}

                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            name="written_by"
                                            label="Written By"
                                            type="text"
                                            id="written_by"
                                            autoComplete="Written By"
                                            onChange={onChange}
                                            value={blog.written_by}
                                            focused={blog.written_by ? true : false}
                                        />
                                        {writtenByErr &&
                                            <Box sx={{ color: 'error.main', display: 'inline' }}>{writtenByErr}</Box>
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            name="blog_image"
                                            label="blogImage (Optional)"
                                            type="file"
                                            id="blog_image"
                                            onChange={onChange}
                                            focused
                                        />
                                        {/* {blogImageErr &&
                                            <Box sx={{ color: 'error.main', display: 'inline' }}>{blogImageErr}</Box>
                                        } */}
                                    </Grid>
                                    {/* <Grid item xs={12}>
                                        <FormControlLabel
                                            control={<Checkbox value="allowExtraEmails" color="primary" />}
                                            label="I want to receive inspiration, marketing promotions and updates via email."
                                        />
                                    </Grid> */}
                                </Grid>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Update
                                </Button>

                            </Box>
                        </form>
                    </Box>

                </Container>
            </ThemeProvider>
        </Box>
    );
}


export default EditBlog