import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
// import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import { EditorState, convertToRaw, ContentState } from 'draft-js';
// import { convertToHTML } from 'draft-convert';
// import { toast } from 'react-toastify';
// import { useFormik } from 'formik';
// import * as Yup from 'yup';
// import globals from '../../config/constant';

import common from '../../config/common';

import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

// const theme = createTheme();
export default function AddBlog() {
    const [credentials, setCredentials] = useState({});
    // const [blogImage, setSetBlogImage] = useState('');
    // const [category, setCategory] = useState('');
    // const [title, setTitle] = useState('');
    // const [description, setDescription] = useState('');  
    // const [writtenBy, setWrittenBy] = useState('');        


    const [blogImageErr, setSetBlogImageErr] = useState('');
    const [categoryErr, setCategoryErr] = useState('');
    const [titleErr, setTitleErr] = useState('');
    const [descriptionErr, setDescriptionErr] = useState('');
    const [writtenByErr, setWrittenByErr] = useState('');
    // const [writtenByErr, setWrittenByErr] = useState('');

    // const oldSearch = useRef('');
    const navigate = useNavigate();

    // const [editorState, setEditorState] = useState(EditorState.createEmpty());
    // const [editorState, setEditorState] = useState(
    //     EditorState.createWithContent(ContentState.createFromText('Initial content'))
    // );
    // const [editorState, setEditorState] = useState(null);
    
    
   

    const onSubmit = async (e) => {
        let params = credentials;
        common.print("on Submit credentials: ");
        common.print(credentials);
        e.preventDefault();
        try {
            await validation();
            try {
                const formData = new FormData();
                formData.append('blog_image', credentials.blog_image);

                const imgRes = await common.apiCallNoEnc("api/upload-a-image", "post", formData)
                // const imgRes = JSON.parse(imgRes1);
                if (imgRes.code === 1) {
                    // common.print("before setting the credentials");
                    // common.print(credentials);
                    // alert(imgRes.data.newFileName);
                    // setCredentials({ ...formData, ['blog_image']:imgRes.data.newFileName});
                    // setCredentials({ ...credentials, blog_image: imgRes.data.newFileName });
                    params.blog_image = imgRes.data.newFileName;
                    // common.print("after setting the credentials");
                    // common.print(credentials);
                    const response = await common.apiCalling("Admin/Blog/add-blog", "post", params)
                    common.print("After adding the blog res");
                    common.print(response);
                    if (response.code === 1) {
                        toast.success(response.message)
                        navigate('/blogs-listing');
                    } else {
                        toast.error(response.message)
                    }
                } else {
                    // alert(2)
                    common.print("Error: while uploading the image");
                    common.print(imgRes)
                    setSetBlogImageErr(imgRes.message)
                    // common.print("Typeof: imgRes")
                    // common.print(typeof imgRes)
                    // common.print("Typeof: imgRes.code")
                    // common.print(typeof imgRes.code)
                    // common.print(imgRes.code)
                    throw imgRes;
                }

            } catch (error) {
                common.print(error);
            }
            // common.print("onSubmit validation resolve res ", credentials);      
            // common.print(credentials)
        } catch (error) {
            common.print(error)
            common.print("onSubmit validation reject err res ", error);
        }
        // common.print("Final credentials: ", credentials);
        // common.print("Final country: ", selectedCountry);  
    }
    const onChange = (e) => {
        // alert(e.target.name)
        if (e.target.name !== 'blog_image') {
            setCredentials({ ...credentials, [e.target.name]: e.target.value })
        } else {
            setCredentials({ ...credentials, [e.target.name]: e.target.files[0] })
        }

        if (e.target.name === 'title' && e.target.value !== '') {
            setTitleErr('');
        }
        if (e.target.name === 'category' && e.target.value !== '') {
            setCategoryErr('');
        }
        if (e.target.name === 'description' && e.target.value !== '') {
            setDescriptionErr('');
        }
        if (e.target.name === 'written_by' && e.target.value !== '') {
            setWrittenByErr('');
        }
        // if (e.target.name === 'email' && e.target.value !== '') {
        //     setEmailErr('');
        // }
    }

    const validation = async () => {
        // common.print("Final credentials: ", credentials);
        // common.print("Final country: ", selectedCountry);
        return new Promise((resolve, reject) => {
            if (!credentials.title) {
                setTitleErr('Title is required.');
                reject();
            } else if (!credentials.category) {
                setCategoryErr('Category is required.');
                reject();
            } else if (!credentials.description) {
                setDescriptionErr("Description is required.");
                reject();
            } else if (!credentials.written_by) {
                setWrittenByErr("Written by is required.")
                reject();
            } else {
                resolve();
            }
        })
    }
    useEffect(() => {
        // const content = 
        // setCredentials({ ...credentials, description: convertToHTML(editorState.getCurrentContent()) });
    }, []);
    return (
        <Box sx={{ width: '100%', mt: 10 }}>

            {/* <ThemeProvider theme={theme}> */}
            <Container component="main" maxWidth="lg">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <PersonAddAlt1Icon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Add a Blog
                    </Typography>
                    <form onSubmit={onSubmit} encType="multipart/form-data">
                        <Box sx={{ mt: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        autoComplete="given-name"
                                        name="title"
                                        fullWidth
                                        id="title"
                                        label="Title"
                                        onChange={onChange}
                                    />
                                    {titleErr &&
                                        <Box sx={{ color: 'error.main', display: 'inline' }}>{titleErr}</Box>
                                    }
                                </Grid>


                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        fullWidth
                                        id="category"
                                        label="Category"
                                        name="category"
                                        onChange={onChange}
                                    />
                                    {categoryErr &&
                                        <Box sx={{ color: 'error.main', display: 'inline' }}>{categoryErr}</Box>
                                    }
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="description"
                                        name="description"
                                        label="Description"
                                        onChange={onChange}
                                        multiline
                                        rows={10} // adjust this value to make the TextField bigger
                                    />
                                    {descriptionErr &&
                                        <Box sx={{ color: 'error.main', display: 'inline' }}>{descriptionErr}</Box>
                                    }
                                </Grid>
                                {/* <Grid item xs={12}>
                                    <Editor
                                        editorState={editorState}
                                        onEditorStateChange={handleEditorChange}
                                    />
                                </Grid> */}
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        name="written_by"
                                        label="writtenBy"
                                        // type="writtenBy"
                                        id="writtenBy"
                                        autoComplete="Written-By"
                                        onChange={onChange}
                                    />
                                    {writtenByErr &&
                                        <Box sx={{ color: 'error.main', display: 'inline' }}>{writtenByErr}</Box>
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        name="blog_image"
                                        label="blogImage"
                                        type="file"
                                        id="blogImage"
                                        onChange={onChange}
                                        focused
                                    />
                                    {blogImageErr &&
                                        <Box sx={{ color: 'error.main', display: 'inline' }}>{blogImageErr}</Box>
                                    }
                                </Grid>
                                {/* <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<Checkbox value="allowExtraEmails" color="primary" />}
                                        label="I want to receive inspiration, marketing promotions and updates via email."
                                    />
                                </Grid> */}
                            </Grid>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Add
                            </Button>
                        </Box>
                    </form>
                </Box>
                {/* <Copyright sx={{ mt: 5 }} /> */}
            </Container>
            {/* </ThemeProvider> */}
        </Box>
    );
}



