let Globals = {
    /* apis key */    
    'key'               : 'iPklauM0xQat8P4Kd4kaN6QitVuKOqb0',
    'iv'                : 'iPklauM0xQat8P4K', // 16 characters
    'iv_real'           : 'iPklauM0xQat8P4Kd4kaN6', //22 characters
    'plain_api_key'           : 'UTSAV*45sIT11!ITSOLUTION', //encrypted_api_key -> don't forget 
    'encrypted_api_key' : '2HNWDcc6O8vnZ998zZb4DHDTM2MN7xHDUSAyLS5mx60=',
    'google_api_key'    : '',
    'api_base_url'          : 'http://localhost:5005/v1/',
    'live_api_base_url'          : 'https://api.utsavitsolution.com/v1/',    
    'BLOG_IMAGE_URL'    : 'https://utsavitsolution.com/images/blogs/',
    'LIVE_SOCKET_URL'   : 'https://api.utsavitsolution.com/socket',
    'LOCAL_SOCKET_URL'   : 'http://localhost:5006/socket',
    'is_production' : true
    // marguerite-729510__340.jpg
}   

export default Globals;
