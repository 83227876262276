import React, { useState, useEffect, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
// import { useDemoData } from '@mui/x-data-grid-generator';
import Swal from 'sweetalert2'
// import globals from '../../config/constant';
import common from '../../config/common';
import { Box, Avatar, Button, TextField, Grid, InputAdornment } from '@mui/material';
import { Link, useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";
import { SearchRounded, Download } from "@mui/icons-material";
import { Stack } from '@mui/system';
// import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import writeXlsxFile from 'write-excel-file'
import moment from 'moment';
import 'moment-timezone';
import ClearIcon from "@material-ui/icons/Clear";
// import { IconButton } from "@material-ui/core";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
// import AddCircleIcon from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';
import { Delete as DeleteIcon } from '@material-ui/icons';
import * as dayjs from 'dayjs'
dayjs().format()

export default function Listing() {

    const navigate = useNavigate();
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [list, setList] = useState({ loading: true });
    const [search, setSearch] = useState('');
    const [isShowingImage, setIsShowingImage] = useState(false);
    const [imageUrl, setIsImageUrl] = useState('');
    const [startDateTime, setStartDateTime] = useState(null);
    const [endDateTime, setEndDateTime] = useState(null);
    const [startUtcDateTime, setUtcStartDateTime] = useState(null);
    const [endUtcDateTime, setUtcEndDateTime] = useState(null);
    const [isDateTimeSearch, setIsDateTimeSearch] = useState(false);

    const [sortModel, setSortModel] = useState([
        {
            field: 'id',
            sort: 'asc',
        },
    ]);
    const clearAllDateTime = useCallback(() => {
        setStartDateTime(null);
        setEndDateTime(null);
        setUtcStartDateTime(null);
        setUtcEndDateTime(null);
        setPage(0);
        setIsDateTimeSearch(isDateTimeSearch ? false : true);
    }, [isDateTimeSearch])

    const exportExcelFile = useCallback(async () => {
        common.apiCalling('admin/export-blogs', 'post', { start_date_time: startUtcDateTime, end_date_time: endUtcDateTime, search }).then(res => {
            if (res.code === 1) {
                export_excel_file(res.data);
            } else {
                // console.log(res.message);
            }
        }).catch(err => {
            console.log(err);
        })

    }, [search, startUtcDateTime, endUtcDateTime])


    const changeStartDateTime = useCallback((value) => {
        //   console.log("changeStartDateTime - 1");
        //  console.log(value);
        if (!Number.isNaN(new Date(value).getTime()) && value && value.$d !== 'Invalid Date' && value.$d.toISOString() && value !== 'Invalid Date') {
            setStartDateTime(value.$d.toISOString());
            setUtcStartDateTime(new moment(startDateTime).utc().format("YYYY-MM-DD HH:mm:ss"));
            if (startUtcDateTime !== null && endUtcDateTime !== null) {
                setIsDateTimeSearch(isDateTimeSearch ? false : true)
            }
            if (startUtcDateTime !== null && endUtcDateTime !== null) {
                // setPage(null);
                setPage(0);
                setIsDateTimeSearch(isDateTimeSearch ? false : true)
                // console.log("Condition not false");
            }
        } else {
            // setIsDateTimeSearch(false)
        }
    }, [endUtcDateTime, isDateTimeSearch, startDateTime, startUtcDateTime]);

    const changeEndDateTime = (value) => {

        if (!Number.isNaN(new Date(value).getTime()) && value && value.$d !== 'Invalid Date' && value.$d.toISOString() && value !== 'Invalid Date') {
            setEndDateTime(value.$d.toISOString());
            let end = new moment(endDateTime).utc().format("YYYY-MM-DD HH:mm:ss");
            setUtcEndDateTime(end);
            if (startUtcDateTime !== null && endUtcDateTime !== null) {
                setIsDateTimeSearch(isDateTimeSearch ? false : true)
            }
            if (startUtcDateTime !== null && endUtcDateTime !== null) {
                // setPage(null);
                setPage(0);
                setIsDateTimeSearch(isDateTimeSearch ? false : true);
                // console.log("Condition not false");
            }
        } else {
            // setIsDateTimeSearch(false)
        }
    };

    useEffect(() => {

        setList({ loading: true });
        let api_data = { page: page, per_page: pageSize, search };
        if (sortModel.length > 0 && sortModel[0].field && sortModel[0].sort) {
            api_data.order_by_value = sortModel[0].field;
            api_data.order_by = sortModel[0].sort;
        }
        if (startUtcDateTime !== null && endUtcDateTime !== null) {
            api_data.start_date_time = startUtcDateTime;
            api_data.end_date_time = endUtcDateTime;
        }


        common.apiCalling("Admin/blog/blogs-listing", "post", api_data).then((res) => {
            setList({ loading: false });
            if (res.code === 1) {
                // common.print(res.data.data)
                res.data.data.forEach((element, index) => {
                    res.data.data[index]['delete_id'] = element.blog_id
                });
                setRows(res.data.data);
                setPageSize(res.data.per_page);
                setTotalRows(res.data.total_results);
            } else {
                setRows([]);
                setTotalRows(0);
            }
        });


        return () => {
            // console.log("came in component will unmount");
        }
    }, [page, search, pageSize, sortModel, isDateTimeSearch, endUtcDateTime, startUtcDateTime])

    const showImage = (image) => {
        setIsShowingImage(true);
        setIsImageUrl(image);
    }

    const closeImageViewer = () => {
        setIsShowingImage(false);
        setIsImageUrl('');
    }

    const editBlog = async (id) => {
        // alert(id);
        // id = await common.encryption(id);
        navigate('/update-blog', { state: { id: id, redirected_from: 'blogs-listing' } });
        // alert(id);
        // <Link to="/onboarding/profile" state={{ from: "occupation" }}>Next Step</Link>
    }

    const deleteBlog = async (id) => {
        // alert(id);
        // id = await common.encryption(id);
        // navigate('/delete-blog', { state: { id: id, redirected_from: 'blogs-listing' } });
        showPopup('Delete!', 'Are you sure you want to delete this blog?', 'warning', 'Confirm', true).then((showPopupVal)=>{
            // alert(showPopupVal)
            if (showPopupVal) {
                let api_data = { blog_id: id, status: 'Deleted' }
                common.apiCalling("Admin/blog/update-blog-status", "POST", api_data).then((res) => {
                    // showPopup(title, message, icon, confirmButtonText, showCancelButton)
                    navigate('/blogs-listing', { state: { id: id, redirected_from: 'blogs-listing' } });
                    setPage(1);
                    setPage(0);
                })
            }
        }).catch((err)=>{
            return false
        })
        

        // alert(id);
        // <Link to="/onboarding/profile" state={{ from: "occupation" }}>Next Step</Link>
    }

    const columns = [
        { field: "id", headerName: 'Blog ID', hide: false },
        { field: 'title', headerName: 'Blog Title', width: 150 },
        {
            field: "blog_image",
            headerName: "Blog Image",
            width: 150,
            renderCell: (params) => {
                console.log("flag renderCall params");
                console.log(params);
                return (
                    <>
                        <Avatar src={(params.formattedValue) ? params.formattedValue : params.value.avatar} onClick={() => { showImage(params.formattedValue) }} />
                        {/* <Button >ok</Button> */}

                        {/* {params.value.username} */}
                    </>
                );
            }
        },
        {
            field: "blog_id",
            headerName: "Action",
            width: 160,
            renderCell: (params) => {
                // console.log("flag renderCall params");
                // console.log(params);
                return (
                    <>
                        {/* <Button variant='contained'><ModeEditIcon /></Button>
            <Button variant='contained'><AddCircleIcon /></Button> #f5f5f5 */}
                        <IconButton aria-label="edit" size="large" style={{
                            // borderRadius: 35,
                            backgroundColor: "#f5f5f5",
                            border: 1
                            // padding: "18px 36px",
                            // fontSize: "18px"
                        }} onClick={async () => { editBlog(params.formattedValue) }}>
                            <ModeEditIcon />
                        </IconButton>
                    </>
                );
            }
        },
        {
            field: "delete_id",
            headerName: "Delete",
            width: 160,
            renderCell: (params) => {
                // console.log("flag renderCall params");
                // console.log(params);
                return (
                    <>
                        {/* <Button variant='contained'><ModeEditIcon /></Button>
            <Button variant='contained'><AddCircleIcon /></Button> #f5f5f5 */}
                        <IconButton aria-label="delete" size="large" style={{
                            // borderRadius: 35,
                            backgroundColor: "#f5f5f5",
                            border: 1
                            // padding: "18px 36px",
                            // fontSize: "18px"
                        }} onClick={async () => { deleteBlog(params.formattedValue) }}>
                            <DeleteIcon />
                        </IconButton>
                    </>
                );
            }
        },
        { field: 'category', headerName: 'Category', width: 150 },
        { field: 'description', headerName: 'Description', width: 250 },
        { field: 'written_by', headerName: 'Written By', width: 150 },
        { field: 'status', headerName: 'Status', width: 150 },
        { field: 'created_at', headerName: 'Created at', width: 150 },

    ];

    const handlePageChange = (newPage) => {
        setPage(newPage)
    }
    const changeRowSize = (e) => {
        setPage(0);
        setPageSize(e);
    }
    const searchByText = (e) => {
        setPage(0);
        setSearch(e.target.value);
    }
    return (
        <Box sx={{ mt: 9 }}>
            <Link to="/add-blog"><Button variant="contained" sx={{ mb: 1 }}>Add Blog</Button></Link>
            {isShowingImage && <Lightbox image={imageUrl} onClose={() => { closeImageViewer() }} />}
            <Box style={{ height: 3, width: '100%' }} />

            <Grid item xs={12} sm={8} md={8} lg={8} >
                <Grid container>
                    <Grid item xs={12} sm={8} md={8} lg={8}>
                        <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            // spacing={{ xs: 1, sm: 10, md: 40, lg: 45 }}
                            justifyContent="flex-start"
                            alignItems="flex-end"
                            spacing={2}
                        >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                {/* <DesktopDatePicker
              label="Date desktop"
              inputFormat="MM/DD/YYYY"
              value={value}
              onChange={handleChange}
              renderInput={(params) => <TextField {...params} />}
            /> */}
                                <DateTimePicker
                                    inputFormat="YYYY-MM-DD HH:mm:ss"
                                    label="Start Date&Time"
                                    value={startDateTime}
                                    maxDateTime={dayjs(endDateTime)}
                                    minTime={dayjs(startDateTime)}
                                    onChange={changeStartDateTime}
                                    renderInput={(params) => <TextField {...params} />}
                                />

                                <DateTimePicker
                                    inputFormat="YYYY-MM-DD HH:mm:ss"
                                    label="End Date&Time"
                                    value={endDateTime}
                                    minDateTime={dayjs(startDateTime)}
                                    minTime={dayjs(startDateTime)}
                                    onChange={changeEndDateTime}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                            <Button onClick={exportExcelFile} startIcon={<Download />} variant='contained'>Export</Button>
                            <Button onClick={clearAllDateTime} variant="contained" color="error">
                                <ClearIcon /> Clear
                            </Button>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                        <Stack direction={'row'} justifyContent="flex-end">
                            <TextField
                                id="standard-search"
                                label="Search field"
                                type="search"
                                variant="standard"
                                onChange={searchByText}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchRounded />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Stack>
                    </Grid>
                </Grid>
                <Box style={{ height: 10, width: '100%' }} />


                <Box style={{ height: 500, width: '100%' }}>
                    {rows && <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={parseInt(pageSize)}
                        rowCount={totalRows}
                        loading={list.loading}
                        pagination
                        onPageChange={(newPage) => handlePageChange(newPage)}
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        onPageSizeChange={changeRowSize}
                        page={page}
                        paginationMode="server"
                        disableColumnFilter
                        components={{ NoRowsOverlay: () => (<Stack height="50%" alignItems="center" justifyContent="center" sx={{ fontWeight: 'bold' }}> No result found </Stack>), NoResultsOverlay: () => (<Stack height="50%" alignItems="center" justifyContent="center" sx={{ fontWeight: 'bold' }}> Local filter returns no result </Stack>) }}
                        rowHeight={40}
                        sortModel={sortModel}
                        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                        sx={{
                            '.css-pdct74-MuiTablePagination-selectLabel': {
                                mt: 2
                                // display: 'block',
                                // justifyContent: 'center',
                            },
                            '.css-levciy-MuiTablePagination-displayedRows': {
                                mt: 2
                                // display: 'block',
                                // justifyContent: 'center',
                            },

                        }}
                    />}
                </Box></Grid>
        </Box>
    );
}

const showPopup = (title, message, icon, confirmButtonText, showCancelButton) => {
    return new Promise(async function (resolve, reject) {
        Swal.fire({
            title: title, //'Logout!',
            text: message, //'Do you want to logout?',
            icon: icon, //'warning',
            confirmButtonText: confirmButtonText, //'Confirm',
            showCancelButton: showCancelButton, //true,
            allowEscapeKey: true
        }).then((result) => {
            if (result.isConfirmed) {
                resolve(true);
            } else {
                resolve(false);
            }
        });
    });
}

const export_excel_file = async (data) => {

    const schema = [
        {
            column: 'Country name',
            type: String,
            value: country => country.country
        }
        //,
        // {
        //   column: 'Date of Birth',
        //   type: Date,
        //   format: 'mm/dd/yyyy',
        //   value: student => student.dateOfBirth
        // },
        // {
        //   column: 'Cost',
        //   type: Number,
        //   format: '#,##0.00',
        //   value: student => student.cost
        // },
        // {
        //   column: 'Paid',
        //   type: Boolean,
        //   value: student => student.paid
        // }
    ]
    await writeXlsxFile(data, {
        schema,
        fileName: 'Countries.xlsx'
    })
}
