import { Routes, Route, Navigate } from "react-router-dom";
import './App.css';
import AdminLogin from './components/Auth/AdminLogin';
import AdminSignup from "./components/Auth/AdminSignup";
import ForgotPassword from "./components/Auth/ForgotPassword";
// import Dashboard from "./components/Dashboard/Dashboard";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import LeftMenu from "./components/inc/LeftMenu";
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
// import { Grid } from "@mui/material";
// import {useMemo} from 'react';
import { useSelector } from 'react-redux';
import ChatListing from "./components/chat/ChatListing";
// import View from "./components/chat/View";

function App() {
    const mode = useSelector((state) => state.global.mode);
    const darkTheme = createTheme({
        palette: {
            mode: mode,
        },
    });
    if (localStorage.getItem('password')) {
        return (
            <>

                <ThemeProvider theme={darkTheme}>
                    <CssBaseline />
                    <ToastContainer />
                    <LeftMenu />

                </ThemeProvider>

            </>


        );
    } else {
        return (
            <ThemeProvider theme={darkTheme}>
                <ToastContainer />
                <CssBaseline />
                <div>

                    {/* <LeftMenu /> */}
                    <div className="container">
                        <Routes>
                            <Route path="/" element={<AdminLogin />} />
                            <Route exact path="/admin-forgot-password" element={<ForgotPassword />} ></Route>
                            <Route exact path="/admin-signup" element={<AdminSignup />} ></Route>
                            <Route exact path="/chat-listing" element={<ChatListing />} ></Route>
                          
                            {/* <Route exact path="/dashboard" element={<Dashboard />} ></Route> */}
                            <Route path="*" element={<Navigate to="/" />} />
                        </Routes>
                    </div>
                    {/* <AdminLogin /> */}
                </div>
            </ThemeProvider>
        );
    }
    // return (
    //     <div>
    //         <Router>
    //             {/* <LeftMenu /> */}
    //             <div className="container">
    //                 <Routes>
    //                     <Route path="/" element={localStorage.getItem("password") ? <Navigate to="/dashboard" /> : <AdminLogin />} />
    //                     <Route exact path="/dashboard" element={localStorage.getItem("password") ? <Dashboard /> : <AdminLogin />} ></Route>
    //                     <Route exact path="/admin-forgot-password" element={!localStorage.getItem("password") ? <ForgotPassword /> : <Navigate to="/dashboard" />} ></Route>
    //                     <Route exact path="/admin-signup" element={!localStorage.getItem("password") ? <AdminSignup /> : <Navigate to="/dashboard" />} ></Route>
    //                     {/* <Route exact path="/dashboard" element={<Dashboard />} ></Route> */}

    //                     <Route path="*" element={<Navigate to ="/" />}/>
    //                 </Routes>
    //             </div>
    //         </Router>

    //         {/* <AdminLogin /> */}
    //     </div>
    // );
}

export default App;
