import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom'

import { configureStore } from "@reduxjs/toolkit";
import globalReducer from "./components/redux/index"
import { Provider } from "react-redux";

const store = configureStore({
  reducer: {
    global: globalReducer,
  },
})


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>

        <App />

      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
