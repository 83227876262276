import React from 'react'
import { Link } from "react-router-dom";
const ForgotPassword = () => {
  return (
    <div>
      <Link className="navbar-brand" to="/">Login </Link>
      <br />
      ForgotPassword
    </div>
  )
}

export default ForgotPassword