import React from 'react';
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
// import {
//     Button
// } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import IconButton from '@mui/material/IconButton';

export const LogoutIconCustom = () => {
    const navigate = useNavigate();
    const logoutme = () => {
        localStorage.clear();
        navigate('/');
        window.location.reload(false);
        return;
    }
    const showMsg = () => {
        Swal.fire({
            title: 'Logout!',
            text: 'Do you want to logout?',
            icon: 'warning',
            confirmButtonText: 'Confirm',
            showCancelButton: true,
            allowEscapeKey: true
        }).then((result) => {
            if (result.isConfirmed) {
                return logoutme();
            } else {
                return;
            }
        });
    }

    return (
        <>
            <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                // aria-controls={menuId}
                aria-haspopup="true"
                onClick={showMsg}
                color="inherit"
            >
                <LogoutIcon />
            </IconButton>
        </>
    )
}


export default LogoutIconCustom